import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as THREE from 'three';

// https://www.youtube.com/watch?v=6oFvqLfRnsU

class App extends Component {

	/*
	handleResize (e) {
		console.log("handleResize");
		this.renderer.setSize( window.innerWidth, window.innerHeight );
		this.camera.aspect = window.innerWidth / window.innerHeight;
	}
	*/

	componentDidMount() {
		// === THREE.JS CODE START ===
		var scene = new THREE.Scene();
		var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
		
		camera.position.z = 4;
		var renderer = new THREE.WebGLRenderer({antialias: true});

		renderer.setClearColor("#e5e5e5");
		renderer.setSize( window.innerWidth, window.innerHeight );
		this.mount.appendChild( renderer.domElement );

		var geometry = new THREE.SphereGeometry(1, 50, 50);
		var material = new THREE.MeshLambertMaterial( {color: 0xFFCC00} );
		var sphere = new THREE.Mesh(geometry, material);

		sphere.position.set(-2.5, 0.5, -1);

		scene.add(sphere);

		var geometry = new THREE.BoxGeometry(1, 1, 1);
		var material = new THREE.MeshLambertMaterial( {color: 0xFFCC00} );
		var qube = new THREE.Mesh(geometry, material);

		qube.position.x = 2;
		qube.position.set(1.5, -1, -1);
		qube.rotation.set(15, 30, 10);
		qube.scale.set(1, 2.25, 1);
		
		scene.add(qube);



		var light = new THREE.PointLight(0xFFFFFF, 0.7, 500);
		var light2 = new THREE.PointLight(0xFFFFFF, 0.4, 600);

		light.position.set(-5, 5, 5);
		light2.position.set(5, 0, 7);
		scene.add(light);
		scene.add(light2);

		var render = function() {
			requestAnimationFrame(render);
			qube.rotation.x += 0.01;
			qube.rotation.y += 0.01;
			renderer.render(scene, camera);
		}

		render();
		/*

		var geometry = new THREE.BoxGeometry( 1, 1, 1 );
		var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
		var cube = new THREE.Mesh( geometry, material );

		scene.add( cube );
		camera.position.z = 5;
		var animate = function () {
			requestAnimationFrame( animate );
			cube.rotation.x += 0.01;
			cube.rotation.y += 0.01;
			renderer.render( scene, camera );
		};
		animate();
		// === THREE.JS EXAMPLE CODE END ===
		*/
	}

	render() {
		return (
			<div ref={ref => (this.mount = ref)} />
		);

	}
}

export default App;
